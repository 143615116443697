<template>
  <v-app class="eudania-main">
    <v-app-bar
        app
        color="primary darken-1"
        flat height ="110"
    >
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-img src="@/assets/logos/logo-tr.png" contain width="160"/>
        </v-toolbar-title>
      <v-container class="py-0 fill-height">
        <v-btn color="secondary"
               v-for="(l, i) in links"
               :key="i"
               text
               :to="l.link"
        >
          {{ l.title }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
              dense
              flat
              hide-details
              rounded
              solo-inverted
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view/>
      <FooterSection/>
    </v-main>

    <v-navigation-drawer 
        v-show="drawer"
        v-model="drawer"
        width="350"
        app
        fixed
        temporary
    >
      <v-card>
        <v-img
            contain
            dark height="100%"
            src="@/assets/logos/logo-gray.jpeg"
        >

        </v-img>
        <v-divider></v-divider>

        <v-list class="list-nav-text text-left"
                dense
                nav
                two-line
        >
          <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
          >
            <v-list-item link :to="l.link" v-for="(l, i) in links" :key="i">
              <v-list-item-icon>
                <v-icon color="primary">
                  {{ l.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ l.name }}</v-list-item-title>
                <v-list-item-subtitle> {{l.subtitle}} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>


          </v-list-item-group>
        </v-list>
        <v-spacer></v-spacer>
      </v-card>


    </v-navigation-drawer>
    <!-- Navigation drawer Area End -->
  </v-app>
</template>
<script>
import { FooterSection} from "@/components";
export default {
  components: {
    FooterSection
  },
  data: () => ({
    drawer: false,
    group: null,
    links: [
      {name: 'Inicio', title: 'Inicio', subtitle: '', icon: 'mdi-home', link: '/'},
      {name: 'Acerca de', title: '¿Quienes somos?', subtitle: '¿Quienes somos?', icon: 'mdi-information-outline', link: '/about'},
      {name: 'Servicios', title: 'Servicios', subtitle: 'Inversión y financiamiento', icon: 'mdi-tools', link: '/services'},
      {name: 'Inversiones', title: 'Inversiones', subtitle: 'Modalidades de inversión', icon: 'mdi-handshake', link: '/investment'},
      {name: 'Contacto', title: 'Contacto', subtitle: 'Nuestros expertos le responderán muy pronto.', icon: 'mdi-face-agent', link: '/contact'}
    ],
  }),
  methods: {
    goHome() {
      this.$router.push("/");
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<style lang="scss">
#app {
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #363232;
  font-weight: 400;

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}

a:focus {
  outline: none;
}


/*================================================
06 - REPONSIVE MENU CSS
==================================================*/

.main {
  position: relative;
}

.site-logo img {
  width: 210px;
}

.site-logo {
  display: inline-block;
  margin: 24px 0;
}
</style>
